<template>
  <div>
    <div class="p-mt-3 p-mb-3">
      <Button v-if="$can('pharmasan.oportunidades.licitaciones.ver-licitaciones.access')" label="Agregar Licitación" class="p-button-success" icon="pi pi-plus" @click="$router.push({ name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones' })"/>
    </div>
    <div class="card">
      <DataTable ref="dt"
                 :value="licitaciones"
                 dataKey="id"
                 :filters="filters"
                 responsiveLayout="scroll"
                 :rowHover="true"
                 class="p-datatable-sm"
                 @sort="sortTable"
                 sortMode="multiple"
                 :multiSortMeta="multiSortMeta"
                 removableSort
      >
        <template #header>
          <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
            <h5 class="mb-2 md:m-0 p-as-md-center">
              Administrar Licitaciones
            </h5>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label for="nombre" class="block text-sm font-medium text-gray-700">Nombre:</label>
              <InputText
                id="nombre"
                type="text"
                class="w-full p-inputtext-sm"
                v-model="filters.nombre"
              />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="fechaApertura" class="block text-sm font-medium text-gray-700">Fecha Inicial:</label>
              <InputText
                id="fechaApertura"
                type="date"
                class="w-full p-inputtext-sm"
                v-model="filters.fechaApertura"
              />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="fechaCierre" class="block text-sm font-medium text-gray-700">Fecha Final:</label>
              <InputText
                id="fechaCierre"
                type="date"
                class="w-full p-inputtext-sm"
                v-model="filters.fechaCierre"
              />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label class="block text-sm font-medium text-gray-700">&nbsp;</label>
              <span class="p-buttonset">
                <Button label="Limpiar Filtros" icon="pi pi-times" @click="resetFilters"
                        class="p-button-sm p-button-warning"/>
                <Button label="Buscar" icon="pi pi-search" @click="search(filters)"
                        class="p-button-sm p-button-info"/>
              </span>
            </div>
          </div>
        </template>
        <Column field="id" header="ID" :sortable="true">
          <template #body="slotProps">
            <div class="flex items-center justify-between">
              <span><strong>{{slotProps.data.id}}</strong></span>
              <Button icon="pi pi-star" v-if="slotProps.data.fav" style="color: #f0c51e" class="p-button-rounded p-button-text" v-tooltip.top="'Marcar favorito'" @click="marcarFav(slotProps)"/>
              <Button icon="pi pi-star" v-else style="color: #eae7e1" class="p-button-rounded p-button-text" v-tooltip.top="'Desmarcar favorito'" @click="marcarFav(slotProps)"/>
            </div>
          </template>
        </Column>
<!--        <Column field="fav" header="Favorito" :sortable="true" style="padding-left: 1rem;"></Column>-->
        <Column field="nombre" header="Nombre" :sortable="true" style="padding-left: 2.5rem;"></Column>
        <Column field="fechaApertura" header="Fecha Apertura">
          <template #body="{data}">
            <span>{{ $h.formatDate(data.fechaApertura,'YYYY-MM-DD') }}</span>
          </template>
        </Column>
        <Column field="fechaCierre" header="Fecha Cierre">
          <template #body="{data}">
            <span>{{ $h.formatDate(data.fechaCierre,'YYYY-MM-DD') }}</span>
          </template>
        </Column>
<!--        <Column field="comentario" header="Comentario">-->
<!--          <template #body="{data}">-->
<!--            <template v-if="data.comentarios">-->
<!--              <template v-if="data.comentarios.length < 10">-->
<!--              <span>-->
<!--                {{ data.comentarios }}-->
<!--              </span>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--              <span>-->
<!--                {{ data.comentarios.substring(0, 10) }}-->
<!--                <span style="color: red;font-weight: bold;" v-tooltip.top="data.comentarios">...</span>-->
<!--              </span>-->
<!--              </template>-->
<!--            </template>-->
<!--          </template>-->
<!--        </Column>-->
        <Column field="lugarEjecucion" header="Lugar"></Column>
        <Column field="Estado" header="Estado" />
        <Column field="tipoRadicacion" header="Tipo">
          <template #body="{data}">
            {{ data.tipoRadicacion ? 'Virtual' : 'Fisico'}}
          </template>
        </Column>
        <Column field="adjuntoPliegos" header="Pliegos">
          <template #body="{data}">
            <div class="flex justify-center">
              <Button icon="pi pi-eye"
                      class="p-button-rounded mr-2"
                      @click="verListarPdfs(data.id)"
                      v-tooltip.top="'Ver adjunto'"
              />
            </div>
          </template>
        </Column>
        <Column field="valorPresupuesto" header="Presupuesto">
          <template #body="{data}">
            <span>{{ $h.formatCurrency(data.valorPresupuesto,0) }}</span>
          </template>
        </Column>
        <Column header="Acciones"
        v-if="$can('pharmasan.oportunidades.licitaciones.ver-licitaciones.access')"
                style="padding-left: 2.5rem;">
          <template #body="{ data }">
            <div class="flex justify-center">
              <div>
                <div class="dropdown relative">
                  <button class=" dropdown-toggle px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="pi pi-cog"/>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="w-2 ml-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
                  </button>
                  <ul class=" dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none" aria-labelledby="dropdownMenuButton1">
                    <li class="flex justify-center p-2">
                      <Button icon="pi pi-pencil" class="p-button-rounded" @click="$router.push({name: 'pharmasan.oportunidades.licitaciones.editar', params: {id: data.id}})" v-tooltip.top="'Editar'"/>
                    </li>
                    <li class="flex justify-center p-2">
                      <Button icon="pi pi-upload" class="p-button-rounded p-button-success" @click="$router.push({name: 'pharmasan.oportunidades.licitaciones.cargar-documentos', params: {id: data.id}})" v-tooltip.top="'Cargar Archivos'"/>
                    </li>
                    <li class="flex justify-center p-2">
                      <Button icon="pi pi-refresh" class="p-button-rounded p-button-help" @click="generarIndices(data.id)" v-tooltip.top="'Generar Indices'" v-if="!data.pathConsolidado"/>
                      <Button icon="pi pi-cog" class="p-button-rounded  p-button-info" @click="$router.push({name: 'pharmasan.oportunidades.licitaciones.modificar-indices', params: {id: data.id}})" v-tooltip.top="'Modificar Indices'" v-else/>
                    </li>
                    <li class="flex justify-center p-2">
                       <Button icon="pi pi-chart-line" class="p-button-rounded p-button-info p-button-outlined" @click="$router.push({name: 'pharmasan.oportunidades.licitaciones.herramientas.indicadores-participacion', params: {id: data.id}})" v-tooltip.left="{value: 'Indicadores de participación'}"/>
                    </li>
                    <li class="flex justify-center p-2">
                        <Button icon="pi pi-file" class="p-button-rounded p-button-help" v-tooltip.top="'Reporte carga de documentos'" @click="generarReporte(data.id)"/>
                    </li>
                    <li class="flex justify-center p-2">
                        <Button icon="pi pi-copy" class="p-button-rounded p-button-help" v-tooltip.top="'Duplicar licitación'" @click="licCopy(data.id)"/>
                    </li>
                    <li class="flex justify-center p-2">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.top="'Eliminar licitación'" @click="licDelete(data.id)"/>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator v-model:first="paginator.first" :rowsPerPageOptions="arrayOptions" :rows="10"  :totalRecords="paginator.totalRecords"  @page="onPage($event)"></Paginator>
    </div>
    <Dialog v-model:visible="documentDialog" :style="{width: '450px'}" header="Agregar Documento" :modal="true"
            class="p-fluid">
      <div class="field">
        <label for="name">Nombre</label>
        <InputText id="name"
                   v-model.trim="documento.name"
                   required="true"
                   autofocus
                   class="p-inputtext-sm p-mt-1"
                   :class="{'p-invalid': validationInput(submitted,documento.name)}"
        />
        <small class="p-error"
               v-if="submitted && !documento.name"
        >
          Nombre es requerido
        </small>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="hideDialog"
        />
        <Button
          label="Guardar"
          icon="pi pi-check"
          class=""
          @click="saveProduct"
        />
      </template>
    </Dialog>
    <Dialog v-model:visible="deleteDocumentDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
        <span v-if="product">Are you sure you want to delete <b>{{ product.name }}</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="deleteDocumentsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
        <span v-if="product">Are you sure you want to delete the selected products?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts"/>
      </template>
    </Dialog>
  </div>
  <Dialog v-model:visible="modalListarPdfs"
          :modal="true"
          :breakpoints="{'960px': '75vw', '640px': '100vw'}"
          :style="{width: '100vw', height: '100vh'}"
          header="Lista Pdfs"
          ref="modalListarPdfsRef"
          :maximizable="true"
  >
    <filePond ref="pond" :url="url" :licitacionId="idLicitacion.toString()"/>
    <DataTable :value="listaPdfs"
               responsiveLayout="scroll"
               :paginator="true"
               :rows="5"
               class="p-datatable-sm"
    >
      <Column field="path" header="Pdf">
        <template #body="{data}">
          <span>{{ data.path.split('/')[2].split('@')[1] }}</span>
        </template>
      </Column>
      <Column field="fullName" header="Usuario"></Column>
      <Column header="Acciones" headerStyle="width: 25%">
        <template #body="{data}">
          <Button label="Ver Pdf" @click="verPdf(data.path)" />
          <Button label="Reemplazar Pdf" class="p-button-success ml-4" @click="reemplazarPdf(data)" />
          <Button label="Eliminar Pdf" class="p-button-danger ml-4" @click="eliminarPdf(data)"/>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <div class="flex justify-end">
        <Button label="Cargar"
                icon="pi pi-save"
                class="mr-2 p-button-success"
                @click="pondCargarPdf"
        />
        <Button label="Salir" class="p-button-danger" @click="modalListarPdfs = !modalListarPdfs"/>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="modalPdf"
          :style="{width: '80%'}"
          :maximizable="true"
          contentStyle="overflow: hidden"
          header="Girar documento"
          :modal="true"
          class="p-fluid"
          ref="modalGirarPdf"
  >
    <div id="embedPdf" style="display: block">
      <embed :src="rutaConsolidado" frameborder="0"
             id="pdf"
             width="100%"
             height="750rem"
      />
    </div>
    <div id="cargando" style="display: none; justify-content: center; align-items: center">
      <ProgressSpinner />
    </div>
    <template #footer>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div class="w-3/4" style="display: flex; align-items: center">
          <Button label="Girar"
                  icon="pi pi-replay"
                  class="mr-2 p-button-warning"
                  @click="girarPdf"
          />
          <div v-for="item of giros" :key="item.key" class="field-radiobutton ml-2">
            <RadioButton :id="item.key" name="category" :value="item.key" v-model="giro" />
            <label :for="item.key">{{item.nombre}}</label>
          </div>
          <InputNumber v-model="pageNumber" placeholder="No. Pag." style="width: 20%; margin-left: 4px"/>
        </div>
      </div>
    </template>
  </Dialog>
  <input type="file"
         ref="adjuntoPliegos"
         id="adjuntoPliegos"
         accept="application/pdf"
         @change="onUpload($event.target)"
         class="hidden"/>
  <input type="file"
         ref="nuevoPliego"
         id="nuevoPliego"
         accept="application/pdf"
         @change="onUploadNuevoPliego($event.target)"
         class="hidden"/>
  <Toast/>
</template>
<script>
import { ref, onMounted, defineAsyncComponent } from 'vue'
import { useToast } from 'primevue/usetoast'
import LicitacionesService from '../../services/LicitacionesService'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
export default {
  name: 'Licitaciones',
  components: {
    filePond: defineAsyncComponent(() => import('../components/uploadFilePound'))
  },
  setup () {
    onMounted(() => {
      getLicitacionesList()
    })
    /** variables */
    const toast = useToast()
    const dt = ref()
    const licitaciones = ref()
    const documentDialog = ref(false)
    const deleteDocumentDialog = ref(false)
    const deleteDocumentsDialog = ref(false)
    const documento = ref({})
    const arrayOptions = ref([])
    const multiSortMeta = ref([{ field: 'fav', order: -1 }, { field: 'id', order: -1 }])
    const _LicitacionesService = ref(new LicitacionesService())
    const filters = ref({
      nombre: '',
      fechaApertura: '',
      fechaCierre: ''
    })
    const paginator = ref({
      rows: 10,
      first: 0,
      totalRecords: 0,
      order: [{ field: 'fav', order: 0 }]
    })
    const submitted = ref(false)
    const statuses = ref([
      { label: 'INSTOCK', value: 'instock' },
      { label: 'LOWSTOCK', value: 'lowstock' },
      { label: 'OUTOFSTOCK', value: 'outofstock' }
    ])
    const router = useRouter()
    const modalListarPdfs = ref(false)
    const listaPdfs = ref([])
    const nuevoPliego = ref()
    const adjuntoPliegos = ref()
    const reemplazarPdfBody = ref({
      id: '',
      oldPdf: ''
    })
    const idLicitacion = ref(null)
    let formData = new FormData()
    const modalPdf = ref(false)
    const rutaConsolidado = ref(null)
    const giros = ref([
      { nombre: '0°', key: '0' },
      { nombre: '90°', key: '90' },
      { nombre: '180°', key: '180' },
      { nombre: '270°', key: '270' }
    ])
    const pageNumber = ref(null)
    const giro = ref(null)
    const pathConsolidadoByDoc = ref(null)
    const modalFilePond = ref(false)
    const pond = ref()
    const url = ref(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/addpdfs`)
    const modalFilePondRef = ref()
    const modalListarPdfsRef = ref()
    const modalGirarPdf = ref()
    const buttonItems = ref([
      {
        label: 'Editar',
        icon: 'pi pi-refresh'
      },
      {
        label: 'Cargar Archivos',
        icon: 'pi pi-upload'
      },
      {
        label: 'Indices',
        icon: 'pi pi-external-link'
      },
      {
        label: 'Indicadores de participación',
        icon: 'pi pi-upload'
      },
      {
        label: 'Reporte carga de documentos',
        icon: 'pi pi-upload'
      },
      {
        label: 'Duplicar licitación',
        icon: 'pi pi-upload'
      }
    ])

    /** methods */
    const openModalPdf = () => {
      modalPdf.value = !modalPdf.value
    }
    const marcarFav = ({ data }) => {
      const params = {
        id: data.id,
        fav: !data.fav
      }
      _LicitacionesService.value.updateFav(params).then(() => {
        getLicitacionesList()
      })
    }
    const openNew = () => {
      documentDialog.value = true
    }
    const hideDialog = () => {
      documentDialog.value = false
      submitted.value = false
    }
    const saveProduct = () => {
      submitted.value = true
    }
    const licDelete = (id) => {
      Swal.fire({
        icon: 'question',
        title: '¿ Esta seguro/a ?',
        text: 'Desea eliminar la licitación',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar',
        cancelButtonText: 'No, Cancelar'
      }).then(({ value }) => {
        if (value) {
          _LicitacionesService.value.deleteLicitacion(id).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Operación realizada',
              text: 'Se elimino exitosamente la licitación'
            }).then(() => {
              getLicitacionesList()
            })
          })
        }
      })
    }
    const getLicitacionesList = () => {
      _LicitacionesService.value.getLicitaciones().then(({ data }) => {
        licitaciones.value = data.rows
        paginator.value.totalRecords = data.count

        if (data.count <= 10) {
          arrayOptions.value = [10]
        }
        if (data.count > 10 && Math.ceil(paginator.value.totalRecords / 3) < 10) {
          arrayOptions.value = [10, paginator.value.totalRecords]
        } else if (data.count > 10) {
          arrayOptions.value = [...new Set([10, Math.ceil(paginator.value.totalRecords / 3), Math.ceil(paginator.value.totalRecords / 2), paginator.value.totalRecords])]
        }
      })
    }
    const viewPdf = (ruta) => {
      _LicitacionesService.value.getPdf(ruta).then((url) => {
        window.open(url, '_blank')
      })
    }

    const validationInput = (a, b) => {
      return a && !b
    }

    const search = () => {
      const filtros = {}
      if (filters.value.nombre) {
        filtros['filters[nombre]'] = filters.value.nombre
      } else if (filters.value.fechaApertura && filters.value.fechaCierre) {
        filtros['filters[fechaApertura]'] = (filters.value.fechaApertura && filters.value.fechaCierre) ? `${filters.value.fechaApertura},${filters.value.fechaCierre}` : ''
      }
      _LicitacionesService.value.getLicitaciones(10, 0, filtros).then(({ data }) => {
        paginator.value.first = 0
        licitaciones.value = data.rows
      })
    }

    const onPage = (event) => {
      _LicitacionesService.value.getLicitaciones(event.rows, event.first, filters.value, paginator.value.order).then(({ data }) => {
        licitaciones.value = data.rows
      })
    }

    const resetFilters = () => {
      filters.value.nombre = ''
      filters.value.fechaApertura = ''
      filters.value.fechaCierre = ''
    }

    const generarIndices = (id) => {
      _LicitacionesService.value.generarIndices(id).then(({ data }) => {
        console.log({ data })
        router.push({ name: 'pharmasan.oportunidades.licitaciones.modificar-indices', params: { id } })
      })
    }

    const verListarPdfs = (id) => {
      idLicitacion.value = id
      modalListarPdfs.value = !modalListarPdfs.value
      if (!modalListarPdfsRef.value.maximized) modalListarPdfsRef.value.maximize()
      _LicitacionesService.value.getListaPdfs(id).then(({ data }) => {
        listaPdfs.value = data
      })
    }

    const pondCargarPdf = () => {
      pond.value.onSubmitSupport().then(async () => {
        _LicitacionesService.value.getListaPdfs(idLicitacion.value).then(({ data }) => {
          listaPdfs.value = data
          pond.value.onRemoveSupport()
        })
      })
    }

    const reemplazarPdf = (data) => {
      adjuntoPliegos.value.click()
      reemplazarPdfBody.value.id = data.id
      reemplazarPdfBody.value.oldPdf = data.path
    }

    const eliminarPdf = (data) => {
      _LicitacionesService.value.eliminarPdf(data.id).then(({ data }) => {
        _LicitacionesService.value.getListaPdfs(idLicitacion.value).then(({ data }) => {
          listaPdfs.value = data
        })
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Actualizado con exito', life: 3000 })
      })
    }

    const verPdf = (ruta) => {
      if (ruta) {
        if (!modalGirarPdf.value.maximized) modalGirarPdf.value.maximize()
        pathConsolidadoByDoc.value = ruta
        _LicitacionesService.value.getPdf(ruta).then((url) => {
          rutaConsolidado.value = url
          openModalPdf()
        })
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
      }
    }

    const onUpload = async (event) => {
      formData.append('adjuntoPliegos', event.files[0])
      formData.append('oldPdf', reemplazarPdfBody.value.oldPdf)
      _LicitacionesService.value.reemplazarPdf(reemplazarPdfBody.value.id, formData).then(({ data }) => {
        _LicitacionesService.value.getListaPdfs(idLicitacion.value).then(({ data }) => {
          listaPdfs.value = data
        })
        formData = new FormData()
        document.getElementById('adjuntoPliegos').value = ''
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Actualizado con exito', life: 3000 })
      })
    }

    const onUploadNuevoPliego = async (event) => {
      formData.append('adjuntoPliegos', event.files[0])
      formData.append('licitacionId', idLicitacion.value)
      _LicitacionesService.value.addPdf(formData).then(({ data }) => {
        _LicitacionesService.value.getListaPdfs(idLicitacion.value).then(({ data }) => {
          listaPdfs.value = data
        })
        formData = new FormData()
        document.getElementById('nuevoPliego').value = ''
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Actualizado con exito', life: 3000 })
      })
    }

    const girarPdf = () => {
      if (pageNumber.value && giro.value) {
        if (pathConsolidadoByDoc.value) {
          document.getElementById('cargando').style.display = 'flex'
          const embed = document.getElementById('embedPdf')
          const element = document.getElementById('pdf')
          element.parentNode.removeChild(element)
          _LicitacionesService.value.girarPdf({
            pathConsolidado: pathConsolidadoByDoc.value,
            page: pageNumber.value,
            rotate: parseInt(giro.value)
          }).then((url) => {
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se giro correctamente', life: 3000 })
            document.getElementById('cargando').style.display = 'none'
            embed.append(element)
            // document.getElementById('pdf').contentDocument = location.reload()
          })
        } else {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
        }
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El numero de pagina o cantidad de giro es obligatorio', life: 3000 })
      }
    }

    const generarReporte = async (id) => {
      const url = await _LicitacionesService.value.generarReporte(id)
      window.open(url, '_blanck')
    }

    const licCopy = (id) => {
      _LicitacionesService.value.licCopy(id).then(() => {
        _LicitacionesService.value.getLicitaciones().then(({ data }) => {
          licitaciones.value = data.rows
        })
      })
    }
    const sortTable = (evt) => {
      const multi = [{ field: 'fav', order: -1 }]
      if (evt.multiSortMeta.reverse()[0]) {
        multi.push(evt.multiSortMeta.reverse()[0])
      }
      multiSortMeta.value = multi
      paginator.value.order = multiSortMeta.value
      _LicitacionesService.value.getLicitaciones(10, event.first, filters.value, paginator.value.order).then(({ data }) => {
        licitaciones.value = data.rows
      })
    }
    const buttonActions = (option, id) => {
      switch (option.label) {
        case 'Editar':
          router.push({ name: 'pharmasan.oportunidades.licitaciones.editar', params: { id } })
          break
        case 'Cargar Archivos':
          router.push({ name: 'pharmasan.oportunidades.licitaciones.cargar-documentos', params: { id } })
          break
        case 'Indices':
          router.push({ name: 'pharmasan.oportunidades.licitaciones.cargar-documentos', params: { id } })
          break
        case 'Indicadores de participación':
          router.push({ name: 'pharmasan.oportunidades.licitaciones.cargar-documentos', params: { id } })
          break
        case 'Reporte carga de documentos':
          router.push({ name: 'pharmasan.oportunidades.licitaciones.cargar-documentos', params: { id } })
          break
        case 'Duplicar licitación':
          router.push({ name: 'pharmasan.oportunidades.licitaciones.cargar-documentos', params: { id } })
          break
      }
    }

    /** return */
    return {
      modalPdf,
      nuevoPliego,
      onUploadNuevoPliego,
      onUpload,
      adjuntoPliegos,
      verPdf,
      reemplazarPdf,
      eliminarPdf,
      verListarPdfs,
      onPage,
      dt,
      search,
      licitaciones,
      documentDialog,
      deleteDocumentDialog,
      deleteDocumentsDialog,
      documento,
      filters,
      submitted,
      statuses,
      openNew,
      hideDialog,
      saveProduct,
      validationInput,
      viewPdf,
      paginator,
      resetFilters,
      generarIndices,
      modalListarPdfs,
      listaPdfs,
      rutaConsolidado,
      giros,
      giro,
      pageNumber,
      girarPdf,
      modalFilePond,
      pond,
      url,
      modalFilePondRef,
      idLicitacion,
      pondCargarPdf,
      modalListarPdfsRef,
      generarReporte,
      licCopy,
      sortTable,
      buttonItems,
      buttonActions,
      licDelete,
      modalGirarPdf,
      marcarFav,
      multiSortMeta,
      arrayOptions
    }
  }
}
</script>
<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
<style>
.p-dialog-mask{
  z-index: 10000 !important;
}
</style>
